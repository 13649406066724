<template>
  <div class="bonus-recommend">
    <div class="container">
      <div class="bonus-recommend__item">
        <div class="h5 text--heavy">
          {{ $t('bonus.card1.title') }}
        </div>
        <p class="text-size-medium">
          CASHBACK BONUS TEAM — <span v-html="$t('bonus.card1.text')"></span>
        </p>
        <div class="decor">
          <svg width="298" height="21" viewBox="0 0 298 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4L24.6786 17L45.3571 4L66.0357 17L86.7143 4L107.393 17L128.071 4L148.75 17L169.429 4L190.107 17L210.786 4L231.464 17L252.143 4L272.821 17L293.5 4" stroke="#18336B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="bonus-recommend__item">
        <div class="h5 text--heavy">
          {{ $t('bonus.card2.title') }}
        </div>
        <p class="text-size-medium">
          {{ $t('bonus.card2.text') }}
        </p>
        <div class="bonus-fake-btn" :class="!isMobile ? 'd-flex justify-space-between' : ''">
          <div class="fake-btn fake-btn--light text-size-medium">
            10% Sale bonus
          </div>
          <div class="fake-btn text-size-medium">
            5% Sale+ bonus
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkMobile from '@/mixins/checkMobile'
export default {
  name: 'BonusRecomend',
  mixins: [checkMobile]
}
</script>

<style lang="scss">
.bonus-recommend {
  padding: 4.5rem 0 8rem;
  @include respond(phone) {
    padding: 24px 0 40px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.2rem;
    align-items: flex-start;
    position: relative;
    @include respond(phone) {
      display: flex;
      flex-direction: column-reverse;
      grid-gap: unset;
    }
  }
  &__item {
    background-color: $color-gray-light;
    border-radius: 16px;
    padding: 5rem;
    position: relative;
    &:first-child {
      margin-top: 11rem;
      @include respond(phone) {
        margin-top: 24px;
      }
    }
    @include respond(phone) {
      padding: 24px;
    }

    .h5 {
      line-height: 130%;
      margin-bottom: .7187em;
      @include respond(phone) {
        margin-bottom: 16px;
      }
    }
    p{
      line-height: 130%;
    }

    .decor {
      position: absolute;
      bottom: 2rem;
      right: 0;
      transform: translateX(40%);
      width: 29.8rem;
      @include respond(phone) {
        transform: translateX(20%);
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.bonus-fake-btn {
  margin-top: 2.3rem;
  @include respond(phone) {
    margin-top: 16px;
  }
}
.fake-btn {
  display: block;
  width: calc(50% - 4px);
  font-weight: 800;
  color: #fff;
  background-color: $color-blue-dark;
  padding: .75em;
  border-radius: 8px;
  text-align: center;
  cursor: default;
  &--light {
    background-color: $color-blue;
  }
  @include respond(phone) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>

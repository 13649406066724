<template>
  <div class="open-business bg-grey-light">
    <div class="decor-line"></div>
    <div class="container">

      <div class="open-business__top" :class="!isMobile ? 'd-flex justify-space-between' : ''">
        <div class="open-business__top__title">
          <div class="h4 text--uppercase text--heavy d-flex justify-space-between">
            <span class="title-text" v-html="$t('openBusiness.title')"></span>
            <span class="decor">
              <svg width="132" height="138" viewBox="0 0 132 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 33.5L36 69L8 104.5" stroke="#27AE60" stroke-opacity="0.5" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M52 33.5L80 69L52 104.5" stroke="#27AE60" stroke-opacity="0.5" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M96 33.5L124 69L96 104.5" stroke="#27AE60" stroke-opacity="0.5" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
        </div>
        <div class="open-business__descr">
          <p class="text-size-medium">
            {{ $t('openBusiness.descr1') }}
          </p>
          <p>
            {{ $t('openBusiness.descr2') }}
          </p>
        </div>
      </div>

      <div class="open-business__features">
        <div class="open-business__features__item">
          <div class="icon">
            <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.8444 2.34264C25.9679 -0.780882 31.0321 -0.780881 34.1556 2.34264L54.6574 22.8444C57.7809 25.9679 57.7809 31.0321 54.6574 34.1556L34.1556 54.6574C31.0321 57.7809 25.9679 57.7809 22.8444 54.6574L2.34264 34.1556C-0.780882 31.0321 -0.780881 25.9679 2.34264 22.8444L22.8444 2.34264Z" fill="#18336B"/>
              <path d="M17 36.6836H40.2734C42.2539 36.6836 43.2852 35.6641 43.2852 33.6719V20.2422C43.2852 18.2617 42.2539 17.2422 40.2734 17.2422H17C15.0195 17.2422 13.9883 18.2617 13.9883 20.2422V33.6719C13.9883 35.6641 15.0195 36.6836 17 36.6836ZM17.0352 34.7969C16.2617 34.7969 15.875 34.4102 15.875 33.6484V20.2773C15.875 19.5039 16.2617 19.1289 17.0352 19.1289H40.2383C41.0117 19.1289 41.3984 19.5039 41.3984 20.2773V33.6484C41.3984 34.4102 41.0117 34.7969 40.2383 34.7969H17.0352ZM24.8398 31.8086C24.8398 32.4414 25.5078 32.6641 26.0117 32.3711L34.1445 27.5898C34.6367 27.2852 34.625 26.6289 34.1328 26.3477L26.0117 21.5547C25.5078 21.2617 24.8398 21.4844 24.8398 22.1172V31.8086ZM22.7422 40.6562H34.5312C35.0469 40.6562 35.4805 40.2344 35.4805 39.707C35.4805 39.1797 35.0469 38.7578 34.5312 38.7578H22.7422C22.2266 38.7578 21.793 39.1797 21.793 39.707C21.793 40.2344 22.2266 40.6562 22.7422 40.6562Z" fill="white"/>
            </svg>
          </div>

          <p class="text-size-medium">
            {{ $t('openBusiness.feature1') }}
          </p>
        </div>

        <div class="open-business__features__item">
          <div class="icon">
            <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.511 2.34264C26.6345 -0.780882 31.6987 -0.780881 34.8223 2.34264L55.324 22.8444C58.4475 25.9679 58.4475 31.0321 55.324 34.1556L34.8223 54.6574C31.6987 57.7809 26.6345 57.7809 23.511 54.6574L3.00927 34.1556C-0.114256 31.0321 -0.114255 25.9679 3.00927 22.8444L23.511 2.34264Z" fill="#FF5F00"/>
              <path d="M23.2526 41.1016H38.4283C38.944 41.1016 39.3658 40.6914 39.3658 40.1641C39.3658 39.7539 39.0846 39.3906 38.7096 39.2617C37.233 38.6758 36.9283 36.7656 38.3815 35.3008C38.8268 34.8555 39.3658 34.2812 39.3658 33.1094V19.668C39.3658 17.2305 38.1588 15.9883 35.733 15.9883H23.2877C20.8619 15.9883 19.6549 17.2188 19.6549 19.668V37.4805C19.6549 39.8945 20.8737 41.1016 23.2526 41.1016ZM35.6393 17.875C36.858 17.875 37.4791 18.5312 37.4791 19.7031V32.8516C37.4791 33.4844 37.1158 33.8477 36.4596 33.8477H24.4596V17.875H35.6393ZM21.5416 34.3281V19.7031C21.5416 18.6836 22.0104 18.0625 22.9244 17.9102V33.8711C22.3854 33.9297 21.9401 34.082 21.5416 34.3281ZM27.6705 30.6367C28.0338 30.6367 28.2916 30.4609 28.444 29.9922L29.194 27.8359H32.8033L33.5533 29.9922C33.7057 30.4609 33.9635 30.6367 34.3268 30.6367C34.7838 30.6367 35.0768 30.3555 35.0768 29.9453C35.0768 29.7812 35.0416 29.6406 34.9713 29.418L32.1237 21.7656C31.9362 21.2383 31.5377 20.9688 30.9869 20.9688C30.4479 20.9688 30.0612 21.2383 29.8737 21.7656L27.026 29.418C26.9557 29.6406 26.9205 29.7812 26.9205 29.9336C26.9205 30.3555 27.2135 30.6367 27.6705 30.6367ZM29.5924 26.6172L30.9401 22.7031H31.0573L32.4049 26.6172H29.5924ZM23.358 39.2148C22.1744 39.2148 21.5416 38.582 21.5416 37.4688C21.5416 36.4141 22.2916 35.7344 23.4635 35.7344H35.4869C35.6393 35.7344 35.7799 35.7227 35.8971 35.6992C35.3463 36.918 35.4401 38.207 36.0612 39.2148H23.358Z" fill="white"/>
            </svg>
          </div>

          <p class="text-size-medium">
            {{ $t('openBusiness.feature2') }}
          </p>
        </div>

        <div class="open-business__features__item">
          <div class="icon">
            <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.1776 2.34264C26.3011 -0.780882 31.3654 -0.780881 34.4889 2.34264L54.9906 22.8444C58.1141 25.9679 58.1141 31.0321 54.9906 34.1556L34.4889 54.6574C31.3654 57.7809 26.3011 57.7809 23.1776 54.6574L2.6759 34.1556C-0.44763 31.0321 -0.447629 25.9679 2.6759 22.8444L23.1776 2.34264Z" fill="#27AE60"/>
              <path d="M21.3723 26.8281C24.1262 26.8281 26.4231 24.543 26.4231 21.7891C26.4231 19.0234 24.1262 16.7266 21.3723 16.7266C18.6067 16.7266 16.3215 19.0234 16.3215 21.7891C16.3215 24.543 18.6184 26.8281 21.3723 26.8281ZM20.7747 24.4961C20.5286 24.5078 20.3645 24.3672 20.1887 24.168L18.8762 22.5859C18.759 22.457 18.7239 22.3164 18.7239 22.1289C18.7239 21.7891 18.9817 21.5195 19.2981 21.5195C19.5208 21.5195 19.6848 21.6133 19.8372 21.8125L20.7512 22.9727L22.8372 19.6211C22.9778 19.4102 23.1536 19.293 23.3645 19.293C23.6926 19.293 23.9739 19.5508 23.9739 19.8438C23.9739 20.0078 23.927 20.1484 23.8215 20.3242L21.3723 24.168C21.2434 24.3789 21.0325 24.5078 20.7747 24.4961ZM29.927 22.6797H41.6926C42.1848 22.6797 42.595 22.293 42.595 21.7891C42.595 21.2969 42.1848 20.9102 41.6926 20.9102H29.927C29.4348 20.9102 29.0364 21.2969 29.0364 21.7891C29.0364 22.293 29.4348 22.6797 29.927 22.6797ZM21.3723 40.3984C24.1262 40.3984 26.4231 38.1016 26.4231 35.3477C26.4231 32.5703 24.1262 30.2852 21.3723 30.2852C18.6067 30.2852 16.3215 32.582 16.3215 35.3477C16.3215 38.1016 18.6184 40.3984 21.3723 40.3984ZM21.3723 38.6875C19.5559 38.6875 18.0325 37.1523 18.0325 35.3477C18.0325 33.5312 19.5676 31.9961 21.3723 31.9961C23.177 31.9961 24.7122 33.5312 24.7122 35.3477C24.7122 37.1523 23.177 38.6875 21.3723 38.6875ZM29.927 36.2266H41.6926C42.1848 36.2266 42.595 35.8398 42.595 35.3477C42.595 34.8438 42.1848 34.457 41.6926 34.457H29.927C29.4348 34.457 29.0364 34.8438 29.0364 35.3477C29.0364 35.8398 29.4348 36.2266 29.927 36.2266Z" fill="white"/>
            </svg>
          </div>

          <p class="text-size-medium">
            {{ $t('openBusiness.feature3') }}
          </p>
        </div>
      </div>

      <div class="open-business__description" :class="!isMobile ? ' d-flex align-center justify-space-between' : ''">
        <div class="open-business__description__left text-size-medium">
          <p>
            {{ $t('openBusiness.text1') }}
          </p>
          <p>
            {{ $t('openBusiness.text2') }}
          </p>
        </div>
        <div class="open-business__description__right">
          <div class="h5 second-font"> 200 000+</div>
          <p>
            {{ $t('openBusiness.notice') }}
          </p>
          <div class="decor decor--top">
            <svg width="45" height="61" viewBox="0 0 45 61" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 37.6508L22.1897 23.0991L40.3793 37.6508" stroke="#2D9CDB" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4 56.75L22.1897 42.1982L40.3793 56.75" stroke="#2D9CDB" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4 18.5517L22.1897 4L40.3793 18.5517" stroke="#2D9CDB" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="decor decor--bottom-left">
            <svg width="298" height="21" viewBox="0 0 298 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4L24.6786 17L45.3571 4L66.0357 17L86.7143 4L107.393 17L128.071 4L148.75 17L169.429 4L190.107 17L210.786 4L231.464 17L252.143 4L272.821 17L293.5 4" stroke="#27AE60" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="decor decor--bottom-right">
            <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M69.612 87.7505C60.7312 92.3181 50.5132 93.5717 40.7911 91.2863C31.069 89.001 22.4791 83.3263 16.5624 75.2803C10.6457 67.2343 7.78942 57.3436 8.50593 47.3825C9.22244 37.4214 13.4649 28.0419 20.4722 20.9265C27.4794 13.8112 36.793 9.42576 46.7419 8.55693C56.6909 7.6881 66.6241 10.3928 74.7597 16.1857C82.8953 21.9786 88.7007 30.4807 91.1345 40.1667" stroke="#FF5F00" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import checkMobile from '@/mixins/checkMobile'
export default {
  name: 'OpenBusiness',
  mixins: [checkMobile]
}
</script>

<style lang="scss">
.open-business {
  margin-top: 16rem;
  padding: 6.8rem 0 3.4rem;
  position: relative;
  @include respond(phone) {
    margin-top: 50px;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .decor-line {
    position: absolute;
    left: 0;
    bottom: 100%;
    height: 16rem;
    width: 100%;
    color: $color-gray-light;
    &::before {
      content: '';
      width: 150%;
      height: 100%;
      background-color: currentColor;
      transform: skewY(4.5deg);
      transform-origin: 0 0;
      position: absolute;
      left: 0;
      top: 0;
    }
    @include respond(phone) {
      height: 50px;
    }
  }

  &__top {
    &__title {
      width: calc(50% - 2.5rem);
      @include respond(phone) {
        width: 100%;
        margin-bottom: 16px;
        .title-text {
          br {
            display: none;
          }
        }
      }
    }
    .decor {
      position: relative;
      width: 13.2rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__descr {
    width: calc(50% - 2.5rem);
    @include respond(phone) {
      width: 100%;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }

  &__features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
    margin-top: 8rem;
    @include respond(phone) {
      grid-template-columns: unset;
      margin-top: 40px;
    }
    &__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5.6rem;
      .icon {
        flex: none;
        width: 5.7rem;
        margin-right: 1.6rem;
        @include respond(phone) {
          width: 57px;
          height: 57px;
          margin-right: 16px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__description {
    &__left {
      width: calc(100% - (30.6rem + 9rem));
      @include respond(phone) {
        width: 100%;
      }
      p {
        max-width: 55rem;
        @include respond(phone) {
          max-width: unset;
          font-size: 16px;
        }
        &:not(:last-child) {
          margin-bottom: 1em;
          @include respond(phone) {
            margin-bottom: 24px;
          }
        }
      }
    }
    &__right {
      width: 30.6rem;
      height: 30.6rem;
      flex: none;
      border-radius: 50%;
      background-color: $color-blue-darken;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      @include respond(phone) {
        width: 316px;
        height: 316px;
        margin: 10px auto 0;
      }
      .h5 {
        font-size: 4rem;
        font-weight: 700;
        @include respond(phone) {
          font-size: 48px;
        }
      }
      p {
        font-size: 2rem;
        max-width: 10em;
        @include respond(phone) {
          font-size: 20px;
        }
      }

      .decor {
        position: absolute;
        svg {
          width: 100%;
          height: 100%;
        }
        &--top{
          left: 0;
          top: 2.4rem;
          width: 4.5rem;
          @include respond(phone) {
            width: 36px;
            left: 24px;
          }
        }
        &--bottom-left {
          bottom: 2rem;
          right: 60%;
          width: 29.8rem;
          z-index: -1;
          @include respond(phone) {
            width: 300px;
          }
        }
        &--bottom-right {
          bottom: 0;
          left: 75%;
          width: 10rem;
          @include respond(phone) {
            width: 84px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="wrapper">
    <HeaderBlock />
    <MainScreen />
    <WhoWeAre ref="about" />
    <OurProducts />
    <Capability />
    <Bonus />
    <BonusRecommend />
    <OpenBusiness />
    <Features />
    <FaqBlock :key="updateKey" />
    <FooterBlock />
  </div>
</template>

<script>
import HeaderBlock from '@/layouts/HeaderBlock'
import MainScreen from '@/views/MainScreen'
import WhoWeAre from '@/views/WhoWeAre'
import OurProducts from '@/views/OurProducts'
import Capability from '@/views/Capability'
import Bonus from '@/views/Bonus'
import BonusRecommend from '@/views/BonusRecomend'
import OpenBusiness from '@/views/OpenBusiness'
import Features from '@/views/Features'
import FaqBlock from '@/views/FaqBlock'
import FooterBlock from '@/layouts/FooterBlock'

export default {
  data() {
    return {
      updateKey: 0,
    }
  },
  components: {
    HeaderBlock,
    MainScreen,
    WhoWeAre,
    OurProducts,
    Capability,
    Bonus,
    BonusRecommend,
    OpenBusiness,
    Features,
    FaqBlock,
    FooterBlock,
  },
  methods: {
    forceUpdate() {
      this.updateKey += 1
    }
  },
  watch: {
    '$i18n.locale'() {
      this.forceUpdate()
    }
  }
}
</script>

<style scoped>
  .wrapper {
    overflow-x: hidden;
  }
</style>

<template>
  <div class="faq" id="faq">
    <div class="decor-line"></div>
    <div class="container" :class="!isMobile ? 'd-flex justify-space-between align-start' : ''">
      <div class="faq__left">
        <div class="h2 second-font text--heavy">
          {{ $t('faq.title') }}
        </div>
      </div>
      <div class="faq__right">
        <Accordion :accordion-content='accordionContent'/>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion'
import checkMobile from '@/mixins/checkMobile'

export default {
  name: 'faq-block',
  mixins: [checkMobile],
  components: {
    Accordion
  },
  data() {
    return {
      accordionContent: [
        {
          questions: this.$t('faq.question1'),
          answer: this.$t('faq.answer1'),
          active: false
        },
        {
          questions: this.$t('faq.question2'),
          answer: this.$t('faq.answer2'),
          active: false
        },
        {
          questions: this.$t('faq.question3'),
          answer: this.$t('faq.answer3'),
          active: false
        },
        {
          questions: this.$t('faq.question4'),
          answer: this.$t('faq.answer4'),
          active: false
        },
        {
          questions: this.$t('faq.question5'),
          answer: this.$t('faq.answer5'),
          active: false
        },
        {
          questions: this.$t('faq.question6'),
          answer: this.$t('faq.answer6'),
          active: false
        },
        {
          questions: this.$t('faq.question7'),
          answer: this.$t('faq.answer7'),
          active: false
        }
      ]
    }
  },
}
</script>

<style lang="scss">
.faq {
  background-color: $color-blue-darken;
  color: #fff;
  padding: 9rem 0;
  position: relative;

  .h2 {
    line-height: 1;
  }

  &__left {
    width: 46%;
    @include respond(phone) {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__right {
    width: 46%;
    @include respond(phone) {
      width: 100%;
    }
  }

  .decor-line {
    width: 100%;
    height: 28.4rem;
    overflow: hidden;
    position: absolute;
    bottom: 100%;
    left: 0;
    color: $color-blue-darken;

    &::before {
      content: '';
      width: 150%;
      height: 100%;
      background-color: currentColor;
      position: absolute;
      left: 0;
      top: 0;
      transform: skewY(-5deg);
      transform-origin: 100% 100%;
    }
  }
}
</style>

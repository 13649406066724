<template>
  <div class="footer">
    <div class="container">
      <div class="footer-main" :class="!isMobile ? 'd-flex align-center justify-space-between' : ''">
        <div class="footer-main__left">
          <div class="h6 text--heavy text--uppercase">{{ $t('footer.title') }}</div>
          <div class="h6"></div>
          <div class="decor">
            <svg width="297" height="21" viewBox="0 0 297 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4 4L24.6429 17L45.2857 4L65.9286 17L86.5714 4L107.214 17L127.857 4L148.5 17L169.143 4L189.786 17L210.429 4L231.071 17L251.714 4L272.357 17L293 4"
                stroke="#FF5F00" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <p>{{ $t('footer.text') }}</p>
        </div>
        <div class="footer-main__right">
          <div class="join">
            <p>
              {{ $t('footer.join.title') }}
            </p>
            <a :href="link" class="btn">{{ $t('footer.join.btn') }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container d-flex justify-space-between align-center">
        <div class="footer__bottom__left d-flex justify-space-between align-start">
          <div class="company-name">
            AWC International ©
          </div>
          <div class="footer__bottom__menu-wrapper d-flex align-start">
            <MenuBlock column/>
            <div class="footer-menu">
              <ul v-if="$i18n.locale !== 'en'">
                <li>
                  <router-link :to="getS3Link('LicenseAgreement_awc')">
                    {{ $t('document.license') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="getS3Link('Privacy_awc')">
                    {{ $t('document.software') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="getS3Link('Rules_of_Partners_awc')">
                    {{ $t('document.rulesOfPartner') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="getS3Link('Сookies_awc')">
                    {{ $t('document.cookies') }}
                  </router-link>
                </li>
              </ul>
              <ul v-else>
                <li>
                  <router-link :to="getS3Link('Independent_Partner_Agreement_AWC')">
                    {{ $t('document.license') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="getS3Link('Privacy_Policy_Notice_AWC')">
                    {{ $t('document.software') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="getS3Link('Policies_and_Procedures_AWC2')">
                    {{ $t('document.procedures') }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="footer-menu">
              <li v-if="$i18n.locale === 'en' || $i18n.locale === 'es'">
                <a href="mailto:support1@awc.international">
                  support1@awc.international
                </a>
              </li>
              <li v-if="$i18n.locale === 'ru' || $i18n.locale === 'kk' || $i18n.locale === 'uk'">
                <a href="mailto:support@awc.international">
                  support@awc.international
                </a>
              </li>
            </div>
          </div>
        </div>
        <SocialList/>
      </div>
    </div>
  </div>
</template>

<script>
import MenuBlock from '@/components/MenuBlock'
import SocialList from '@/components/SocialList'

import checkMobile from '@/mixins/checkMobile'

export default {
  name: 'Footer-block',
  mixins: [checkMobile],
  components: {
    MenuBlock,
    SocialList
  },
  methods: {
    getS3Link(name) {
      let lang = this.$i18n.locale
      if (lang === 'uk') {
        lang = 'ua'
      }
      if (lang === 'kk') {
        lang = 'kz'
      }
      // Для en локали показываем документы для США, так как в папке us на S3 названия не совпадают с остальными папками, то сделано таким образом
      if (lang === 'en') {
        lang = 'us'
      }
      return '/docs/' + lang + '/' + name
    }
  },
  computed: {
    link() {
      const lang = this.$i18n.locale
      return `https://allsmartpatch.com/?locale=${lang}`
    }
  }
}
</script>

<style lang="scss">
.footer {
  color: #fff;
  background-color: $color-black;

  &-main {
    padding: 10.8rem 0 8.8rem;
    @include respond(phone) {
      padding: 40px 0;
    }

    &__left {
      width: 50%;
      @include respond(phone) {
        width: 100%;
        margin-bottom: 24px;
      }

      .h6 {
        margin-bottom: 1.6rem;
        @include respond(phone) {
          margin-bottom: 16px;
        }
      }

      .decor {
        margin: 4rem 0;
        width: 29.7rem;
        @include respond(phone) {
          margin: 16px 0;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      p {
        line-height: 1.5;
      }
    }

    &__right {
      max-width: 41.6rem;
      @include respond(phone) {
        max-width: unset;
      }
    }
  }

  &__bottom {
    padding: 4rem 0;
    background-color: #2C2C2C;
    @include respond(tab-port) {
      .container {
        align-items: flex-start;
        @include respond(phone) {
          display: block;
        }
      }
    }
    @include respond(phone) {
      padding: 24px 0;
    }

    &__left {
      width: 55%;
      @include respond(tab-port) {
        width: 100%;
        flex-wrap: wrap;
      }
      @include respond(phone) {
        margin-bottom: 30px;
      }
    }

    &__menu-wrapper {
      flex-grow: 1;
      @include respond(tab-port) {
        flex-grow: unset;
        width: 100%;
      }
      @include respond(phone) {
        display: block !important;
      }

      & > *:not(:last-child) {
        margin-right: 4rem;
        @include respond(phone) {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.join {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  color: #fff;
  padding: 4.8rem;
  @include respond(phone) {
    padding: 24px;
  }

  p {
    margin-bottom: 1em;
    @include respond(phone) {
      font-size: 13px;
      margin-bottom: 24px;
    }
  }

  .btn {
    display: block;
    border: 3px solid #FFFFFF;
    border-radius: 56px;
    font-size: 2.4rem;
    padding: 1em .3em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
    }

    @include respond(phone) {
      font-size: 16px;
      padding: 1.35em 1em;
    }
  }
}

.company-name {
  font-size: 14px;
  color: #7B7B7B;
  line-height: 150%;
  margin-right: 5em;
  white-space: nowrap;
  @include respond(tab-port) {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.footer-menu {
  font-size: 14px;
  line-height: 150%;
  white-space: nowrap;

  li {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    a {
      &:hover {
        color: $color-primary
      }
    }
  }
}
</style>

import { vueWindowSizeMixin } from 'vue-window-size/option-api'
export default {
  mixins: [vueWindowSizeMixin()],
  computed: {
    isMobile() {
      return this.$windowWidth < 640
    },
    isTabPort() {
      return this.$windowWidth < 991
    },
    isTabLand() {
      return this.$windowWidth < 1280
    },
    isSmallDesctop() {
      return this.$windowWidth < 1440
    }
  }
}

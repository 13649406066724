import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { createI18n } from 'vue-i18n'
import { VueWindowSizePlugin } from 'vue-window-size/option-api'
import VueSmoothScroll from 'vue3-smooth-scroll'

import en from './locales/common/en.json'
import ru from './locales/common/ru.json'
import uk from './locales/common/uk.json'
import kk from './locales/common/kk.json'
import es from './locales/common/es.json'

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
  messages: {
    en: Object.assign({}, en),
    ru: Object.assign({}, ru),
    uk: Object.assign({}, uk),
    kk: Object.assign({}, kk),
    es: Object.assign({}, es)
  }
})

createApp(App)
  .use(i18n)
  .use(router)
  .use(VueWindowSizePlugin)
  .use(VueSmoothScroll, {
    duration: 600,
    updateHistory: false
  })
  .mount('#app')

<template>
  <ul class="social d-flex">
    <li>
      <a :href="youtubeLink" target="_blank">
        <span class="icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M21.9954 12.0164C21.9954 12.0164 21.9954 15.2612 21.5837 16.8259C21.353 17.6824 20.6777 18.3577 19.8213 18.5881C18.2566 19 11.9978 19 11.9978 19C11.9978 19 5.75537 19 4.17432 18.5718C3.31787 18.3413 2.64258 17.6658 2.41187 16.8093C2 15.2612 2 12 2 12C2 12 2 8.75537 2.41187 7.19067C2.64233 6.33423 3.33423 5.64233 4.17432 5.41187C5.73901 5 11.9978 5 11.9978 5C11.9978 5 18.2566 5 19.8213 5.42822C20.6777 5.65869 21.353 6.33423 21.5837 7.19067C22.012 8.75537 21.9954 12.0164 21.9954 12.0164ZM10.0049 14.9976L15.2095 12L10.0049 9.00244V14.9976Z"
                fill="currentColor"/>
          <mask id="mask0_83_378" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="5" width="20" height="14">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M21.9954 12.0164C21.9954 12.0164 21.9954 15.2612 21.5837 16.8259C21.353 17.6824 20.6777 18.3577 19.8213 18.5881C18.2566 19 11.9978 19 11.9978 19C11.9978 19 5.75537 19 4.17432 18.5718C3.31787 18.3413 2.64258 17.6658 2.41187 16.8093C2 15.2612 2 12 2 12C2 12 2 8.75537 2.41187 7.19067C2.64233 6.33423 3.33423 5.64233 4.17432 5.41187C5.73901 5 11.9978 5 11.9978 5C11.9978 5 18.2566 5 19.8213 5.42822C20.6777 5.65869 21.353 6.33423 21.5837 7.19067C22.012 8.75537 21.9954 12.0164 21.9954 12.0164ZM10.0049 14.9976L15.2095 12L10.0049 9.00244V14.9976Z"
                fill="currentColor"/>
          </mask>
          <g mask="url(#mask0_83_378)">
          </g>
        </svg>
      </span>
      </a>
    </li>
<!--    <li>-->
<!--      <a :href="telegramLink" target="_blank">-->
<!--        <span class="icon">-->
<!--        <svg width="189.473" height="189.473" viewBox="0 0 189.473 189.473" style="enable-background:new 0 0 189.473 189.473;" xml:space="preserve">-->
<!--            <g>-->
<!--              <path fill="currentColor" d="M152.531,179.476c-1.48,0-2.95-0.438-4.211-1.293l-47.641-32.316l-25.552,18.386c-2.004,1.441-4.587,1.804-6.914,0.972-->
<!--                c-2.324-0.834-4.089-2.759-4.719-5.146l-12.83-48.622L4.821,93.928c-2.886-1.104-4.8-3.865-4.821-6.955-->
<!--                c-0.021-3.09,1.855-5.877,4.727-7.02l174.312-69.36c0.791-0.336,1.628-0.53,2.472-0.582c0.302-0.018,0.605-0.018,0.906-0.001-->
<!--                c1.748,0.104,3.465,0.816,4.805,2.13c0.139,0.136,0.271,0.275,0.396,0.42c1.11,1.268,1.72,2.814,1.835,4.389-->
<!--                c0.028,0.396,0.026,0.797-0.009,1.198c-0.024,0.286-0.065,0.571-0.123,0.854L159.898,173.38c-0.473,2.48-2.161,4.556-4.493,5.523-->
<!--                C154.48,179.287,153.503,179.476,152.531,179.476z M104.862,130.579l42.437,28.785L170.193,39.24l-82.687,79.566l17.156,11.638-->
<!--                C104.731,130.487,104.797,130.533,104.862,130.579z M69.535,124.178l5.682,21.53l12.242-8.809l-16.03-10.874-->
<!--                C70.684,125.521,70.046,124.893,69.535,124.178z M28.136,86.782l31.478,12.035c2.255,0.862,3.957,2.758,4.573,5.092l3.992,15.129-->
<!--                c0.183-1.745,0.974-3.387,2.259-4.624L149.227,38.6L28.136,86.782z"/>-->
<!--            </g>-->
<!--          </svg>-->
<!--      </span>-->
<!--      </a>-->
<!--    </li>-->
  </ul>
</template>

<script>
export default {
  name: 'SocialList',
  computed: {
    youtubeLink() {
      if (this.$i18n.locale === 'ru') {
        return 'https://www.youtube.com/channel/UCABR21ATX9ukF_1JQJXpJTg'
      }
      if (this.$i18n.locale === 'es') {
        return 'https://www.youtube.com/channel/UC11JXZtWuLCr68zSb0Z9gQA'
      }
      return 'https://www.youtube.com/channel/UCw4bybMw1UGdzc9TFdMlfHQ'
    },
    telegramLink() {
      if (this.$i18n.locale === 'ru') {
        return 'https://t.me/aware_win_choice'
      }
      if (this.$i18n.locale === 'es') {
        return 'https://t.me/aware_win_choice_es'
      }
      return 'https://t.me/aware_win_choice_eng'
    }
  }
}
</script>

<style scoped lang="scss">
.social {
  li {
    &:not(:last-child) {
      margin-right: 2rem;
    }

    a {
      color: currentColor;

      &:hover {
        color: $color-primary
      }
      .icon {
        display: block;
        width: 24px;
        height: 24px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

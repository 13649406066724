<template>
  <div class="capability" id="capability">
    <div class="container">
      <div class="h2 second-font text--black">{{ $t('capability.title') }}</div>
      <div class="capability-content d-flex align-center justify-space-between">
        <div class="capability-content__descr">
          <div class="h4 text--heavy text--uppercase">{{ $t('capability.subtitle') }}</div>
          <p class="text-size-medium">
            {{ $t('capability.text') }}
          </p>
        </div>
        <div class="capability-content__img">
          <img :src="require('@/assets/img/capability/1.jpeg')" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkMobile from '@/mixins/checkMobile'
export default {
  name: 'CapabilityBlock',
  mixins: [checkMobile]
}
</script>

<style lang="scss">
.capability {
  padding: 9rem 0;
  .h2 {
    line-height: 1.19;
    margin-bottom: .6875em;
    @include respond(phone) {
      margin-bottom: 47px;
    }
  }
  &-content {
    @include respond(phone) {
      flex-direction: column-reverse;
    }
    &__descr {
      max-width: 50rem;
      @include respond(tab-port) {
        max-width: unset;
      }
      .h4 {
        line-height: 1.1875;
        margin-bottom: .3em;
        @include respond(tab-port) {
          margin-bottom: 16px;
        }
      }
      p {
        line-height: 1.16;
      }
    }
    &__img {
      max-width: 58.5rem;
      position: relative;
      line-height: 0;
      @include respond(phone) {
        max-width: unset;
        margin-bottom: 60px;
      }
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 25px;
        background-color: $color-primary;
        position: absolute;
        top: 0;
        left: 0;
        @include respond(tab-port) {
          top: -4rem;
          left: -4rem;
        }
        @include respond(phone) {
          top: 0;
          left: 0;
          max-width: 80%;
        }
      }
      img {
        width: 100%;
        border-radius: 25px;
        position: relative;
        transform: translate(8rem, 4rem);
        z-index: 1;
        @include respond(tab-port) {
          transform: translate(0, 0);
        }
        @include respond(phone) {
          transform: translate(7rem, 4rem);
          max-width: 80%;
        }
      }
    }
  }
}
</style>

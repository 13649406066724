<template>
  <div class="features">
    <div class="container">
      <div class="features-list">
        <div class="features-list__item">
          <div class="icon">
            <svg width="90" height="43" viewBox="0 0 90 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M45.1797 20.8174C50.2188 20.8174 54.5547 16.3174 54.5547 10.458C54.5547 4.66895 50.1953 0.379883 45.1797 0.379883C40.1875 0.379883 35.8047 4.7627 35.8281 10.5049C35.8281 16.3174 40.1641 20.8174 45.1797 20.8174ZM18.1562 21.3564C22.5391 21.3564 26.3125 17.4189 26.3125 12.333C26.3125 7.29395 22.5156 3.56738 18.1562 3.56738C13.8203 3.56738 9.97656 7.3877 10 12.3799C10 17.4189 13.7969 21.3564 18.1562 21.3564ZM72.1328 21.3564C76.5156 21.3564 80.3125 17.4189 80.3125 12.3799C80.3125 7.3877 76.4922 3.56738 72.1328 3.56738C67.7969 3.56738 63.9766 7.29395 63.9766 12.333C63.9766 17.4189 67.7734 21.3564 72.1328 21.3564ZM5.10156 42.5205H22.8906C20.4531 38.9814 23.4297 31.8564 28.4922 27.9658C25.8672 26.2314 22.5156 24.9424 18.1562 24.9424C7.58594 24.9424 0.976562 32.7471 0.976562 39.2393C0.976562 41.3486 2.125 42.5205 5.10156 42.5205ZM85.2109 42.5205C88.1875 42.5205 89.3359 41.3486 89.3359 39.2393C89.3359 32.7471 82.7266 24.9424 72.1562 24.9424C67.7734 24.9424 64.4219 26.2314 61.8203 27.9658C66.8594 31.8564 69.8359 38.9814 67.3984 42.5205H85.2109ZM30.4609 42.5205H59.8984C63.5781 42.5205 64.8906 41.4658 64.8906 39.4033C64.8906 33.3564 57.3203 25.0127 45.1797 25.0127C33.0156 25.0127 25.4453 33.3564 25.4453 39.4033C25.4453 41.4658 26.7578 42.5205 30.4609 42.5205Z"
                fill="#18336B" fill-opacity="0.5"/>
            </svg>
          </div>
          <div class="h6" v-html="$t('features.card1.title')"></div>
          <p>
            {{ $t('features.card1.text') }}
          </p>
        </div>

        <div class="features-list__item">
          <div class="icon icon--bonus">
            <svg width="58" height="72" viewBox="0 0 58 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M27.1562 15.5986C27.5625 15.5986 27.7812 15.3486 27.8438 14.9736C28.7812 9.91113 28.6875 9.66113 34.125 8.69238C34.5 8.59863 34.75 8.37988 34.75 7.97363C34.75 7.59863 34.5 7.34863 34.125 7.28613C28.6875 6.31738 28.7812 6.06738 27.8438 1.00488C27.7812 0.629883 27.5625 0.379883 27.1562 0.379883C26.75 0.379883 26.5312 0.629883 26.4688 1.00488C25.5312 6.06738 25.625 6.31738 20.1875 7.28613C19.7812 7.34863 19.5625 7.59863 19.5625 7.97363C19.5625 8.37988 19.7812 8.59863 20.1875 8.69238C25.625 9.66113 25.5312 9.91113 26.4688 14.9736C26.5312 15.3486 26.75 15.5986 27.1562 15.5986ZM12.0312 37.0986C12.625 37.0986 13.0312 36.6924 13.0938 36.1299C14.2188 27.7861 14.5 27.7861 23.125 26.1299C23.6562 26.0361 24.0625 25.6611 24.0625 25.0674C24.0625 24.5049 23.6562 24.0986 23.125 24.0361C14.5 22.8174 14.1875 22.5361 13.0938 14.0674C13.0312 13.4736 12.625 13.0674 12.0312 13.0674C11.4688 13.0674 11.0625 13.4736 10.9688 14.0986C9.96875 22.4424 9.5 22.4111 0.9375 24.0361C0.40625 24.1299 0 24.5049 0 25.0674C0 25.6924 0.40625 26.0361 1.0625 26.1299C9.5625 27.5049 9.96875 27.7236 10.9688 36.0674C11.0625 36.6924 11.4688 37.0986 12.0312 37.0986ZM33.2188 71.6611C34.0312 71.6611 34.625 71.0674 34.7812 70.2236C37 53.1299 39.4062 50.5049 56.3438 48.6299C57.2188 48.5361 57.8125 47.9111 57.8125 47.0674C57.8125 46.2549 57.2188 45.6299 56.3438 45.5361C39.4062 43.6611 37 41.0361 34.7812 23.9111C34.625 23.0674 34.0312 22.5049 33.2188 22.5049C32.4062 22.5049 31.8125 23.0674 31.6875 23.9111C29.4688 41.0361 27.0312 43.6611 10.125 45.5361C9.21875 45.6299 8.625 46.2549 8.625 47.0674C8.625 47.9111 9.21875 48.5361 10.125 48.6299C27 50.8486 29.3438 53.1299 31.6875 70.2236C31.8125 71.0674 32.4062 71.6611 33.2188 71.6611Z"
                fill="#18336B" fill-opacity="0.5"/>
            </svg>
          </div>
          <div class="h6">
            {{ $t('features.card2.title') }}
          </div>
          <p v-html="$t('features.card2.text')"></p>
          <p class="text--lt">
            {{ $t('features.card2.notice') }}
          </p>
        </div>

        <div class="features-list__item">
          <div class="icon">
            <svg width="89" height="52" viewBox="0 0 89 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.5 51.8174H81.8125C85.9375 51.8174 88.7812 49.1299 88.7812 45.1611V15.3799C88.7812 11.4111 85.9375 8.72363 81.8125 8.72363H80.7188V7.59863C80.7188 3.22363 78.375 0.973633 74.0312 0.973633H18.0312C13.9062 0.973633 11.3438 3.22363 11.3438 7.59863V44.6924H4.5C2.5625 44.6924 0.96875 46.2861 0.96875 48.2549C0.96875 50.2236 2.5625 51.8174 4.5 51.8174ZM61.25 15.3799V44.6924H16.375V9.00488C16.375 7.00488 17.375 6.00488 19.375 6.00488H72.6875C74.5938 6.00488 75.5938 6.91113 75.6875 8.72363H68.2188C64.0938 8.72363 61.25 11.4111 61.25 15.3799ZM65.4688 16.0361C65.4688 14.0986 66.6875 12.9424 68.7812 12.9424H69.125V14.1924C69.125 15.2861 69.8438 16.0674 70.9375 16.0674H79.125C80.2188 16.0674 80.9375 15.2861 80.9375 14.1924V12.9424H81.2812C83.3438 12.9424 84.5625 14.0986 84.5625 16.0361V44.5049C84.5625 46.4424 83.3438 47.5986 81.2812 47.5986H68.7812C66.6875 47.5986 65.4688 46.4424 65.4688 44.5049V16.0361ZM70.375 45.5986H79.7188C80.4375 45.5986 80.9375 45.1299 80.9375 44.4111C80.9375 43.6299 80.4375 43.1611 79.7188 43.1611H70.375C69.625 43.1611 69.125 43.6299 69.125 44.4111C69.125 45.1299 69.625 45.5986 70.375 45.5986Z"
                fill="#18336B" fill-opacity="0.5"/>
            </svg>
          </div>
          <div class="h6">
            {{ $t('features.card3.title') }}
          </div>
          <p>
            {{ $t('features.card3.text') }}
          </p>
        </div>

        <div class="features-list__item">
          <div class="icon">
            <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.69531 43.333C3.75 43.333 4.57031 42.4893 4.57031 41.4346C4.57031 20.9502 20.0156 4.19238 38.9531 4.19238C57.8906 4.19238 73.3359 20.9502 73.3359 41.4346C73.3359 42.4893 74.1562 43.333 75.1875 43.333C76.2422 43.333 77.0859 42.4893 77.0859 41.4346C77.0859 18.8643 59.9531 0.442383 38.9531 0.442383C17.9531 0.442383 0.820312 18.8643 0.820312 41.4346C0.820312 42.4893 1.64062 43.333 2.69531 43.333ZM39 26.0361C43.125 26.0361 46.7109 22.333 46.7109 17.5049C46.7109 12.7471 43.1016 9.20801 39 9.20801C34.875 9.20801 31.2656 12.8174 31.2656 17.5518C31.2656 22.333 34.8516 26.0361 39 26.0361ZM14.625 43.3096C15.6562 43.3096 16.5 42.4658 16.5 41.4346C16.5 32.7393 21 24.7939 28.0781 20.5518C27.7969 19.3564 27.6797 17.833 27.8203 16.4268C18.7031 20.9268 12.75 30.6299 12.75 41.4346C12.75 42.4658 13.5703 43.3096 14.625 43.3096ZM63.2578 43.3096C64.3125 43.3096 65.1562 42.4658 65.1562 41.4346C65.1562 30.7002 59.2266 20.9971 50.1562 16.4736C50.2969 17.8564 50.2031 19.2861 49.875 20.6455C56.9297 24.8174 61.4062 32.7393 61.4062 41.4346C61.4062 42.4658 62.2266 43.3096 63.2578 43.3096ZM26.0391 43.9189H51.9375C53.9766 43.9189 55.2422 42.958 55.2422 41.3643C55.2422 36.3721 48.9844 29.4814 39 29.4814C28.9688 29.4814 22.7344 36.3721 22.7344 41.3643C22.7344 42.958 23.9766 43.9189 26.0391 43.9189Z"
                fill="#18336B" fill-opacity="0.5"/>
            </svg>
          </div>
          <div class="h6">
            {{ $t('features.card4.title') }}
          </div>
          <p>
            {{ $t('features.card4.text') }}
          </p>
        </div>

        <div class="features-list__item features-list__item--img">
          <div class="features-list__item__img">
            <img :src="require('@/assets/img/features/1.jpeg')" alt="">
          </div>
        </div>

        <div class="features-list__item">
          <div class="icon">
            <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.4844 50.7393C16.4219 50.7393 17.0781 50.2705 18.1797 49.2393L25 42.8877C23.6875 41.833 22.6094 40.1689 22.6094 36.3486V20.8096C22.6094 13.6377 26.9688 9.34863 34.1641 9.34863H50.5703L50.5469 8.48145C50.1953 3.65332 47.0312 0.536133 41.6641 0.536133H9.50781C4.14062 0.536133 0.601562 3.74707 0.601562 9.34863V32.3643C0.601562 37.9189 4.14062 41.5049 9.50781 41.5049H13.3984V48.3486C13.3984 49.7783 14.1719 50.7393 15.4844 50.7393ZM53.3594 53.9736C54.6953 53.9736 55.4453 53.0361 55.4453 51.583V44.7627H57.9297C63.2969 44.7627 66.8359 41.1768 66.8359 35.5986V21.3486C66.8359 15.7705 63.2969 12.5596 57.9297 12.5596H34.7031C29.1016 12.5596 25.8203 15.7705 25.8203 21.3721V35.5986C25.8203 41.1768 29.1016 44.7627 34.7031 44.7627H42.2031L50.6641 52.4971C51.7656 53.5049 52.4219 53.9736 53.3594 53.9736Z"
                fill="#18336B" fill-opacity="0.5"/>
            </svg>
          </div>
          <div class="h6">
            {{ $t('features.card5.title') }}
          </div>
          <p>
            {{ $t('features.card5.text') }}
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesBlock'
}
</script>

<style lang="scss">
.features {
  padding: 4.5rem 0;
  background-color: $color-gray-light;
  position: relative;
}
.features-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  &__item {
    width: 45%;
    margin-bottom: 7rem;
    @include respond(phone) {
      width: 100%;
      margin-bottom: 48px;
    }
    &:nth-child(even) {
      padding-left: 9rem;
      @include respond(phone) {
        padding-left: 0;
      }
    }
    &--img {
      @include respond(phone) {
        order: 8
      }
    }
    .icon {
      line-height: 0;
      margin-bottom: 1.3rem;
      @include respond(phone) {
        margin-bottom: 10px;
      }
      &--bonus {
        margin-left: -5rem;
        margin-bottom: -2rem;
        @include respond(tab-port) {
          margin-left: 0;
          margin-bottom: 0;
        }
        @include respond(phone) {
          margin-bottom: 10px;
        }
      }
    }
    .h6 {
      font-weight: 800;
      margin-bottom: .75em;
      @include respond(phone) {
        margin-bottom: 8px;
      }
    }
    p {
      max-width: 44.6rem;
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      @include respond(phone) {
        font-size: 14px;
        max-width: unset;
      }
    }

    &__img {
      position: relative;
      width: 57.4rem;
      @include respond(tab-port) {
        width: 100%;
      }
      @include respond(phone) {
        width: 240px;
      }
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 25px;
        background-color: $color-primary;
        position: absolute;
        top: 5rem;
        left: 8rem;
        @include respond(tab-port) {
          top: 3rem;
          left: 4rem;
        }
        @include respond(phone) {
          top: 30px;
          left: 30px;
        }
      }
      img {
        width: 100%;
        border-radius: 25px;
        position: relative;
        z-index: 1;
      }
    }
  }
}
</style>

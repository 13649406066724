<template>
  <div class="lang">
    <div class="lang-active" @click="show = !show">
      <div class="current-lang">
        <span class="icon">
          <img :src="require(`@/assets/img/flags/flag_${currentLang }.svg`)" alt="">
        </span>
      </div>
    </div>
    <ul class="lang-list" v-if="show">
      <li @click="setLang(item.val)" v-for="(item, index) in languages" :key="index">
        <span class="icon">
          <img :src="require(`@/assets/img/flags/flag_${item.val}.svg`)" alt="">
        </span>
<!--          <span class="country-name">{{ item.countryName }}</span>-->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return {
      show: false,
      currentLang: 'ru',
      currentCountryIcon: '',
      languages: [
        {
          countryName: this.$t('lang.en'),
          val: 'en'
        },
        {
          countryName: this.$t('lang.ru'),
          val: 'ru'
        },
        {
          countryName: this.$t('lang.kz'),
          val: 'kk'
        },
        {
          countryName: this.$t('lang.ua'),
          val: 'uk'
        },
        {
          countryName: this.$t('lang.ua'),
          val: 'es'
        },
      ]
    }
  },
  methods: {
    setLang(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      this.show = false
      this.currentLang = lang
    }
  },
  mounted() {
    this.currentLang = localStorage.getItem('lang') || 'ru'
    this.$i18n.locale = localStorage.getItem('lang') || this.currentLang
  }
}
</script>

<style scoped lang="scss">
.lang {
  position: relative;
  &-active {
    cursor: pointer;
  }
  .icon {
    display: inline-flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &-list {
    position: absolute;
    top: 100%;
    right: -20px;
    background-color: #fff;
    border-radius: 16px;
    padding: 8px 2px;
    //width: 230px;
    box-shadow: -2px 4px 10px rgba(0, 0, 0, .1);
    z-index: 10;
    li {
      font-size: 16px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background: #F2F2F2;
      }
    }
  }
}
</style>

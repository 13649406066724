<template>
  <div class="main-screen text-color-white">
    <div class="container" :class="!isMobile ? 'd-flex' : ''">
      <div class="main-screen__descr" :class="isMobile ? 'w-100' : ''">
        <div class="h1 second-font text--black">
            <span v-if="isAWC">
              AWARE <br>WIN CHOICE
            </span>
            <span v-else>
               Be2Reach
            </span>
        </div>
        <div class="h6">
          {{ $t('firstScreen.descr1') }}
        </div>
        <p>
          {{ $t('firstScreen.descr2') }}
        </p>
      </div>
      <div class="main-screen__img" :class="isMobile ? 'w-100' : ''">
        <img :src="require('@/assets/img/main-photo.png')" alt="">
        <span class="decor decor--top">
          <svg width="87" height="136" viewBox="0 0 87 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 128L43.5 100L79 128" stroke="#FF5F00" stroke-width="16" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M8 82L43.5 54L79 82" stroke="#FF5F00" stroke-width="16" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M8 36L43.5 8L79 36" stroke="#FF5F00" stroke-width="16" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </span>
        <span class="decor decor--bottom">
          <svg width="1092" height="59" viewBox="0 0 1092 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 8L47.5 51L87 8L126.5 51L166 8L205.5 51L245 8L284.5 51L324 8L363.5 51L403 8L442.5 51L482 8L521.5 51L561 8L600.5 51L640 8L679.5 51L719 8L758.5 51L798 8L837.5 51L877 8L916.5 51L956 8L995.5 51L1035 8L1074.5 51L1114 8"
              stroke="#2D9CDB" stroke-width="16" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import checkMobile from '@/mixins/checkMobile'
import defineProject from '@/mixins/defineProject'

export default {
  name: 'MainScreen',
  mixins: [checkMobile, defineProject]
}
</script>

<style lang="scss" scoped>
.main-screen {
  background-color: $color-blue-darken;
  padding: 2rem 0 9rem;
  @include respond(phone) {
    padding: 40px 0;
  }

  &__descr {
    width: 60%;
    padding-top: 8rem;
    @include respond(phone) {
      padding-top: 0;
      margin-bottom: 23px;
    }

    .h1 {
      line-height: 1;
      margin-bottom: .3em;
      @include respond(phone) {
        margin-bottom: 16px;
      }
    }

    .h6 {
      line-height: 1.2;
      margin-bottom: .9em;
      max-width: 49rem;
      @include respond(phone) {
        max-width: unset;
        margin-bottom: 16px;
        line-height: 1.3;
      }
    }

    p {
      font-size: 2rem;
      max-width: 57rem;
      line-height: 1.2;
      @include respond(phone) {
        font-size: 14px;
        line-height: 1.3;
        max-width: unset;
      }
    }
  }

  &__img {
    width: 40%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;

    & > img {
      width: 75rem;
      position: relative;
      transform: translateX(35%);
      z-index: 1;
      @media(max-width: 1400px) {
        width: 60rem;
        transform: translateX(25%);
      }
      @include respond(phone) {
        width: 75rem;
        transform: translateX(25%);
      }
    }
  }

  .decor {
    position: absolute;

    svg {
      width: 100%;
      height: 100%;
    }

    &--top {
      z-index: 2;
      top: 1rem;
      left: 10%;
      width: 8.7rem;
      @include respond(phone) {
        left: auto;
        right: 38rem;
      }
    }

    &--bottom {
      bottom: 11%;
      right: 0;
      width: 100rem;
      @media(max-width: 1400px) {
        width: 50rem;
      }
      @include respond(phone) {
        width: 70rem;
        right: -3rem;
      }
    }
  }
}
</style>

export default {
  computed: {
    isAWC() {
      if (process.env.VUE_APP_PROJECT === 'awc') {
        console.log('11111')
        return true
      }
      console.log('222222')
      return false
    }
  }
}

<template>
  <ul class="menu" :class="column ? 'flex-direction-column align-start' : ''">
    <li><a href="#about" v-smooth-scroll>{{ $t('menu.about') }}</a></li>
    <li><a href="#capability" v-smooth-scroll>{{ $t('menu.capability') }}</a></li>
    <li><a href="#faq" v-smooth-scroll>{{ $t('menu.faq') }}</a></li>
  </ul>
</template>

<script>
export default {
  name: 'MenuBlock',
  props: {
    column: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.menu {
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  @include respond(phone) {
    font-size: 14px;
  }
  li {
    margin-right: 2.2em;
    cursor: pointer;
    transition: all .3s;
    @include respond(phone) {
      margin-right: 1em;
    }
    &:hover {
      color: $color-primary
    }
  }
  &.flex-direction-column {
    li {
      margin-right: 0;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>

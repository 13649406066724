<template>
  <div class="header">
    <div class="container d-flex justify-space-between align-center">
      <div class="header__left">
        <img :src="require('@/assets/img/logo.svg')" alt="Aware Win Choice" v-if="isAWC">
        <img :src="require('@/assets/img/B2Reach_logo.svg')" alt="Aware Win Choice" v-else>
      </div>
      <div class="header__right d-flex align-center">
        <MenuBlock v-if="!isMobile"/>
        <div class="btns-container d-flex align-center" v-if="!isMobile">
          <a href="https://allsmartpatch.com" class="btn btn--primary mr-1">{{ $t('btn.toPartner') }}</a>
          <a :href="linkToOffice" class="btn btn--outline">
            <span class="icon">
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 12L3 12" stroke="#18336B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.8848 9.10571L13.8128 12.0217L10.8848 14.9377" stroke="#18336B" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                  d="M6.50439 7.38897V6.45597C6.50439 4.42097 8.1534 2.77197 10.1894 2.77197H15.0734C17.1034 2.77197 18.7484 4.41697 18.7484 6.44697V17.587C18.7484 19.622 17.0984 21.272 15.0634 21.272H10.1784C8.1494 21.272 6.50439 19.626 6.50439 17.597V16.655"
                  stroke="#D4D7DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            {{ $t('btn.enter') }}
          </a>
        </div>
        <ChooseLang/>
      </div>
    </div>
    <div class="btns-container d-flex align-center justify-center mt-1" v-if="isMobile">
      <a href="https://allsmartpatch.com" class="btn btn--primary mr-1" style="width: 48%; justify-content: center">{{ $t('btn.toPartner') }}</a>
      <a href="https://office.awc.center/login" class="btn btn--outline" style="width: 48%; justify-content: center">
            <span class="icon">
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 12L3 12" stroke="#18336B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.8848 9.10571L13.8128 12.0217L10.8848 14.9377" stroke="#18336B" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                  d="M6.50439 7.38897V6.45597C6.50439 4.42097 8.1534 2.77197 10.1894 2.77197H15.0734C17.1034 2.77197 18.7484 4.41697 18.7484 6.44697V17.587C18.7484 19.622 17.0984 21.272 15.0634 21.272H10.1784C8.1494 21.272 6.50439 19.626 6.50439 17.597V16.655"
                  stroke="#D4D7DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
        {{ $t('btn.enter') }}
      </a>
    </div>
  </div>
</template>

<script>
import MenuBlock from '@/components/MenuBlock'
import ChooseLang from '@/components/ChooseLang'

import checkMobile from '@/mixins/checkMobile'
import defineProject from '@/mixins/defineProject'

export default {
  name: 'Header-block',
  mixins: [checkMobile, defineProject],
  components: {
    MenuBlock,
    ChooseLang
  },
  computed: {
    linkToOffice() {
      const url = window.location.href
      if (url.indexOf('https://awc.capital') !== -1) {
        return 'https://office.awc.capital/login'
      }
      return 'https://office.awc.international/login'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 1.5rem 0;
}
.btns-container{
  margin-right: 2rem;
}
.btn {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 5px 20px;
  height: 40px;
  @include respond(phone) {
    padding: 5px;
  }
  &--primary {
    background-color: $color-primary;
    color: #fff;
    &:hover {
      background: darken($color-primary, 10%);
    }
  }
  &--outline {
    border: 2px solid #D4D7DD;
    &:hover {
      color: $color-primary;
      border-color: currentColor;
      path {
        stroke: currentColor;
      }
    }
  }
  .icon {
    margin-right: .5rem;
    transition: all .1s;
  }
}
</style>

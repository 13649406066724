import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/views/HomePage'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/docs/:lang/:name',
    name: 'docs',
    props: true,
    component: () => import('./views/docs/PdfDocument'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

<template>
  <div class="who-we bg-grey-light" id="about">
    <div class="container" :class="!isMobile ? ' d-flex justify-space-between' : ''">
      <div class="h2 second-font text--black" :class="isMobile ? 'w-100' : ''">{{ $t('about.title') }}</div>
      <div class="who-we__descr" :class="isMobile ? 'w-100' : ''">
        <div class="h5">
          {{ $t('about.text1') }}
        </div>

        <div class="decor">
          <svg width="298" height="21" viewBox="0 0 298 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 4L24.6786 17L45.3571 4L66.0357 17L86.7143 4L107.393 17L128.071 4L148.75 17L169.429 4L190.107 17L210.786 4L231.464 17L252.143 4L272.821 17L293.5 4"
              stroke="#FF5F00" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>

        <p class="text-size-medium">
          {{ $t('about.text2') }}
        </p>
      </div>
    </div>
    <div class="arrow-down-wrapper text--center">
      <div class="arrow-down">
        <svg width="80" height="36" viewBox="0 0 80 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M75.9595 4L40.4595 32L4.95948 3.99999" stroke="#FF5F00" stroke-width="8" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import checkMobile from '@/mixins/checkMobile'
export default {
  name: 'WhoWeAre',
  mixins: [checkMobile]
}
</script>

<style lang="scss">
.who-we {
  padding: 8rem 0 5rem;
  @include respond(phone) {
    padding: 40px 0 30px;
  }

  .h2 {
    width: 35%;
    @include respond(phone) {
      margin-bottom: 24px;
    }
  }

  &__descr {
    width: 50%;

    .h5 {
      line-height: 130%;
    }
    p {
      line-height: 130%;
    }
  }

  .decor {
    margin: 2.7rem 0;
    width: 29.8rem;
    @include respond(phone) {
      margin: 16px 0;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.arrow-down-wrapper {
  padding-top: 5rem;
}
.arrow-down {
  width: 8rem;
  margin-left: auto;
  margin-right: auto;
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>

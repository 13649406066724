<template>
  <div class="our-product bg-blue-dark text-color-white">
    <div class="container">
      <div class="our-product__top" :class="!isMobile ? 'd-flex justify-space-between align-center' : ''">
        <div class="h2 text--black second-font">
          {{ $t('product.title') }}
        </div>
        <div class="our-product__top__descr">
          <p class="text-size-medium">
            <span class="text--uppercase text--bold">AWARE WIN CHOICE</span>
            {{ $t('product.descr') }}
          </p>
        </div>
      </div>

      <div class="product-list">
        <div class="product-list__item">
          <div class="product-list__item__img">
            <img :src="require('@/assets/img/products/1.jpeg')" alt="">
          </div>
          <div class="product-list__item__descr">
            <p>
              {{ $t('product.card1') }}
            </p>
          </div>
        </div>

        <div class="product-list__item">
          <div class="product-list__item__img">
            <img :src="require('@/assets/img/products/2.jpeg')" alt="">
          </div>
          <div class="product-list__item__descr">
            <p>
              {{ $t('product.card2') }}
            </p>
          </div>
        </div>

        <div class="product-list__item">
          <div class="product-list__item__img">
            <img :src="require('@/assets/img/products/3.jpeg')" alt="">
          </div>
          <div class="product-list__item__descr">
            <p>
              {{ $t('product.card3') }}
            </p>
          </div>
        </div>
      </div>

      <div class="our-product__banner">
        <div class="h3 second-font text--heavy d-flex align-center justify-center">
          {{ $t('product.notice') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkMobile from '@/mixins/checkMobile'
export default {
  mixins: [checkMobile]
}
</script>

<style lang="scss">
.our-product {
  padding: 10.8rem 0;
  @include respond(phone) {
    padding: 56px 0;
  }

  &__top {
    margin-bottom: 12rem;
    .h2 {
      line-height: 1;
      @include respond(phone) {
        br {
          display: none;
        }
      }
    }

    &__descr {
      max-width: 44rem;
      @include respond(phone) {
        max-width: unset;
        margin-top: 24px;
      }
    }
  }
}
.product-list {
  display: grid;
  grid-gap: 12rem;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 12rem;
  @include respond(phone) {
    display: block;
  }
  &__item {
    @include respond(phone) {
      max-width: 360px;
      margin: 0 auto 60px;
    }
    &__img {
      margin-bottom: 4.8rem;
      @include respond(phone) {
        margin-bottom: 32px;
      }
      img {
        width: 100%;
        border-radius: 35px;
      }
    }
    &__descr {
      line-height: 1.3;
      p {
        &:not(:last-child) {
          margin-bottom: 1em;
        }
        @include respond(phone) {
          font-size: 18px;
          line-height: 1.5;
        }
      }
    }
  }
}
.our-product__banner {
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    position: absolute;
    left: 2rem;
    top: 2rem;
    border-radius: 32px;
    z-index: -1;
    @include respond(phone) {
      border-radius: 8px;
      left: 10px;
      top: 10px;
    }
  }
  .h3 {
    font-size: 5rem;
    font-weight: 800;
    color: #fff;
    background-color: $color-blue;
    border-radius: 32px;
    text-align: center;
    min-height: 22.2rem;
    padding: 1rem 2rem;
    @include respond(phone) {
      font-size: 12px;
      min-height: 46px;
      border-radius: 8px;
    }
  }
}
</style>
